import BigNumber from 'bignumber.js'
import React, { FC } from 'react'
import Modal from 'react-modal'

import { getRootElement } from '../../helpers/appHelpers'
import { ReactComponent as IconClose } from '../../images/icons/close.svg'
import { NetworkConfigV3, TokenWithApprovalContext, TxnError } from '../../model'
import Message, { MessageIcons } from '../Message'
import NetworkFeeDesktop from '../NetworkFee/NetworkFeeDesktop'
import SwapSummary from '../SwapSummary'
import { TooltipAnchor } from '../TooltipAnchor'
import { feeTooltipText, scanErrorTooltipText } from './resources'
import ViewOn from './ViewOn'

interface FailedTxnModalProps {
  onClose: () => void
  fromAmount?: BigNumber
  fromTokenUsdPrice: BigNumber
  gasCostInNativeToken?: BigNumber
  gasCosts?: BigNumber
  networkConfig: NetworkConfigV3
  hashTxn?: string
  tokenFrom: TokenWithApprovalContext
  tokenTo: TokenWithApprovalContext
  toAmount?: BigNumber
  toTokenUsdPrice: BigNumber
  txnError?: TxnError
}

const FailedTxnModal: FC<FailedTxnModalProps> = ({
  onClose,
  tokenFrom,
  tokenTo,
  networkConfig,
  toAmount,
  fromAmount,
  fromTokenUsdPrice,
  toTokenUsdPrice,
  gasCostInNativeToken,
  gasCosts,
  txnError,
  hashTxn,
}) => {
  if (toAmount === undefined || fromAmount === undefined) {
    return null
  }

  const errorMessage = (
    <div className="text">
      <p>
        Unfortunately your swap transaction failed. Most common reasons for failed transactions are
        due to low slippage and/or gas set. Please review your transaction on and try again.
      </p>
      <p>
        For more information about failed transactions, learn more from our{' '}
        <a
          className="docs-link"
          href="https://docs.dex.guru/general/troubleshooting-errors"
          target="_blank"
          rel="noopener noreferrer">
          Docs
        </a>
      </p>
    </div>
  )

  return (
    <Modal
      parentSelector={getRootElement}
      isOpen={true}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      className="modal-content">
      <div className=" modal confirm-modal">
        <div className="modal__close" onClick={(): void => onClose()}>
          <IconClose />
        </div>
        <div className="modal__header">
          <h4 className="modal__title">Ooops... Something went wrong with your Swap</h4>
        </div>
        <div className="modal__body">
          <SwapSummary
            tokenFrom={tokenFrom}
            tokenTo={tokenTo}
            tokenNetwork={networkConfig.name}
            fromAmount={fromAmount}
            toAmount={toAmount}
            fromAmountSelectedCurrency={fromAmount.times(fromTokenUsdPrice)}
            toAmountSelectedCurrency={toAmount.times(toTokenUsdPrice)}
            readonly={true}
            failed={true}
          />
          <div className="card card--dark">
            <div className="card__body">
              <div className="confirm-modal__card-line">
                <NetworkFeeDesktop
                  title="Transaction Fee Paid:"
                  tooltipText={feeTooltipText}
                  tokenNetwork={networkConfig.name}
                  gasCosts={gasCosts}
                  gasCostInNativeToken={gasCostInNativeToken}
                  readonly={true}
                  fromAmountSelectedCurrency={fromAmount.times(fromTokenUsdPrice)}
                />
              </div>
              <div className="confirm-modal__card-line">
                <div className="swap-verify-data__header">
                  <div className="row row--no-gap row--align-center">
                    <span className="label">TX Error:</span>
                    <TooltipAnchor tooltip={scanErrorTooltipText} />
                  </div>
                </div>
                <div className="swap-verify__text">
                  {txnError?.message || txnError?.originalError || '-'}
                </div>
              </div>
              {networkConfig && hashTxn && (
                <div className="confirm-modal__card-line">
                  <ViewOn hashTxn={hashTxn} networkConfig={networkConfig} />
                </div>
              )}
            </div>
          </div>
          <div className="card card--clear">
            <div className="card__header">
              <h4 className="card__title">What's next?</h4>
            </div>
            <Message
              type="error"
              icon={MessageIcons.error}
              text={errorMessage}
              size="lg"
              className="message--card"
            />
          </div>
        </div>
        <div className="modal__footer">
          <div className="row row--justify-center">
            <div className="cell cell--auto">
              <button
                className="modal__action button button--lg button--secondary"
                onClick={onClose}>
                <span className="caption">Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FailedTxnModal
